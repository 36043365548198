<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th class="text-center" style="min-width: 100px">
              <SelectFilter
                class="c-input-xs input-filter"
                :options="bankOptions"
                :label="$t('labels.bank')"
                :placeholder="$t('labels.bank')"
                name="id_bank"
                sort-name="bank_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="min-width: 150px">
              <InputFilter
                :label="$t('labels.bank_branch')"
                :placeholder="$t('labels.bank_branch')"
                name="branch"
                sort-name="branch"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="min-width: 150px">
              <InputFilter
                :label="$t('labels.bank_account_number')"
                :placeholder="$t('labels.bank_account_number')"
                name="account"
                sort-name="account"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="min-width: 150px">
              <InputFilter
                :label="$t('labels.bank_account_owner')"
                :placeholder="$t('labels.bank_account_owner')"
                name="owner"
                sort-name="owner"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="min-width: 75px">
              {{ $t("labels.active") }}
            </th>
            <th class="text-center" style="width: 165px">
              <v-btn small color="primary" @click="addNewAccount"
                ><v-icon>mdi-plus</v-icon> {{ $t("labels.add") }}</v-btn
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id" class="text-center">
            <td>
              <v-autocomplete
                class="c-input-small input-disabled-bold"
                v-model="item.id_bank"
                :items="bankOptions"
                :disabled="!item.editing || !!item.id"
                :label="$t('labels.bank')"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td class="">
              <v-text-field
                v-model="item.branch"
                :disabled="!item.editing || !!item.id"
                class="c-input-small input-disabled-bold"
                :placeholder="$t('labels.bank_branch')"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td class="">
              <v-text-field
                v-model="item.account"
                :disabled="!item.editing || !!item.id"
                class="c-input-small input-disabled-bold"
                :placeholder="$t('labels.bank_account_number')"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td class="">
              <v-text-field
                v-model="item.owner"
                :disabled="!item.editing || !!item.id"
                class="c-input-small input-disabled-bold"
                :placeholder="$t('labels.bank_account_owner')"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td class="text-center">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center">
              <v-btn
                v-if="!item.editing"
                class="mr-1"
                x-small
                color="warning"
                @click="toggleEditing(item, index, true)"
                >{{ $t("labels.edit") }}</v-btn
              >
              <template v-else>
                <v-btn
                  class="mr-1"
                  x-small
                  color="success"
                  @click="updateBankAccount(item)"
                  >{{ $t("labels.save") }}</v-btn
                >
                <v-btn
                  class="mr-1"
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "Bank",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
  },
  data: () => ({
    isLoading: false,
    filters: {},
    items: [],
    eMarketOptions: [],
    authLink: {},
    dialogConfigSyncStock: false,
    configItem: {},
    configIndex: -1,
    bankOptions: [],
  }),
  watch: {
    filters: {
      handler() {
        this.getBankAccount();
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.getBank();
    this.getBankAccount();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    addNewAccount() {
      const item = {
        editing: true,
        id: null,
        id_bank: null,
        branch: null,
        account: null,
        owner: null,
        status: 1,
      };
      this.items.unshift(item);
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    async updateBankAccount(account) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/update-bank-account", account);
        this.isLoading = false;
        const msg = account.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.getBankAccount();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async getBank() {
      try {
        const { data } = await httpClient.post("/get-all-bank");
        this.bankOptions = [...data].map((item) => {
          const text = [item.name];
          if (item.short_name) {
            text.push(`(${item.short_name})`);
          }
          return {
            value: item.id,
            text: text.join(" "),
          };
        });
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async getBankAccount() {
      try {
        const { data } = await httpClient.post(
          "/get-all-bank-account",
          this.filters
        );
        this.items = [...data];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
